import React, { useState } from "react";
import html2canvas from "html2canvas";
import './App.css';

function App() {
  const [numColors, setNumColors] = useState(5);
  const [baseColor, setBaseColor] = useState("#ff0000"); // Default base color
  const [shadeType, setShadeType] = useState("both"); // Shade type: light, dark, or both
  const [colors, setColors] = useState([]);

  // Function to convert RGB to HEX
  const rgbToHex = (r, g, b) => {
    return "#" + [r, g, b].map(x => x.toString(16).padStart(2, '0')).join('');
  };

  // Function to convert RGB to HSL
  const rgbToHsl = (r, g, b) => {
    r /= 255; g /= 255; b /= 255;
    let max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: break;
      }
      h /= 6;
    }
    return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
  };

  // Function to generate lighter or darker shades
  const generateShadedColor = (baseColor, factor) => {
    const color = parseInt(baseColor.slice(1), 16); // Convert HEX to an integer
    let r = (color >> 16) & 255;
    let g = (color >> 8) & 255;
    let b = color & 255;

    // Lighten or darken each channel
    r = Math.min(Math.max(Math.floor(r * factor), 0), 255);
    g = Math.min(Math.max(Math.floor(g * factor), 0), 255);
    b = Math.min(Math.max(Math.floor(b * factor), 0), 255);

    return { rgb: `rgb(${r}, ${g}, ${b})`, hex: rgbToHex(r, g, b), hsl: `hsl(${rgbToHsl(r, g, b).join(", ")})` };
  };

  // Generate color palette with shades
  const generatePalette = () => {
    const newColors = [];
    const stepFactor = shadeType === "both" ? 2 : 1; // 2-step for both light and dark

    for (let i = 0; i < numColors; i++) {
      let factor;
      if (shadeType === "light") {
        factor = 1 + (i / numColors) * 0.5; // Lighten the color
      } else if (shadeType === "dark") {
        factor = 1 - (i / numColors) * 0.5; // Darken the color
      } else {
        factor = i < numColors / 2 
          ? 1 + (i / (numColors / 2)) * 0.5  // Lighten first half
          : 1 - ((i - numColors / 2) / (numColors / 2)) * 0.5;  // Darken second half
      }
      newColors.push(generateShadedColor(baseColor, factor));
    }
    setColors(newColors);
  };

  // Copy color value to clipboard
  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    alert("Copied: " + value);
  };

  // Download palette as an image using html2canvas
  const downloadImage = () => {
    html2canvas(document.querySelector(".palette")).then((canvas) => {
      const link = document.createElement("a");
      link.download = "color_palette.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <div className="App">
      <h1>Shaded Color Palette Generator</h1>
      
      <label>Number of Colors:</label>
      <input
        type="number"
        value={numColors}
        min="1"
        max="100"
        onChange={(e) => setNumColors(e.target.value)}
      />
      
      <label>Base Color:</label>
      <input
        type="color"
        value={baseColor}
        onChange={(e) => setBaseColor(e.target.value)}
      />

      <label>Shade Type:</label>
      <select value={shadeType} onChange={(e) => setShadeType(e.target.value)}>
        <option value="both">Both</option>
        <option value="light">Light</option>
        <option value="dark">Dark</option>
      </select>
      
      <button onClick={generatePalette}>Generate Palette</button>
      <button onClick={downloadImage}>Download Palette as Image</button>

      <div className="palette">
        {colors.map((color, index) => (
          <div
            key={index}
            className="color-box"
            style={{ backgroundColor: color.rgb }}
          >
            <p onClick={() => copyToClipboard(color.rgb)}><strong>RGB:</strong> {color.rgb}</p>
            <p onClick={() => copyToClipboard(color.hex)}><strong>HEX:</strong> {color.hex}</p>
            <p onClick={() => copyToClipboard(color.hsl)}><strong>HSL:</strong> {color.hsl}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
